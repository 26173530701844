.icon {
    width: 22px;
    height: 22px;
}
.general {
    display: flex;
    margin-top: 12px;
}

.general > img {
    margin-right: 8px;
}
