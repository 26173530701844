@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');

.mt-12 {
  margin-top: 12px;
}

.row {
  display: flex;
  column-gap: 16px;
}
.row > * {
  margin-top: 16px;
}

p {
  
  font-family: Manrope, sans-serif;
}

.miniWarn {
  color: red;
}


