.editButton {
    cursor: pointer;
    color: blue;
}
.editButtonWrapper {
    display: flex;
    justify-content: space-between;
}
.actionButtons {
    display: flex;
    justify-content: flex-end;
}
.actionButtons > span:first-child {
    cursor: pointer;
    margin-right: 16px;
}
.actionButtons > span:last-child {
    cursor: pointer;
    color: blue;
}

