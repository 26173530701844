.dataContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .mainContainer {
    padding: 0 15%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-y: auto;
    height: 100vh;
    scroll-behavior: smooth;
  }
  
  .paymentContainer {
    margin-left: 32px;
    width: 40%;
    display: flex;
    flex-direction: column;
    position: -webkit-sticky;
    position: sticky;
    overflow-y: auto;
    top: 0;
  }
  
  .paymentContainer::-webkit-scrollbar {
    display: none;
  }
  
  
  .generalTitle {
    text-align: center;
    margin-top: 24px;
  }
  

  @media (max-width: 1200px) {
    .dataContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  
    .mainContainer {
      flex-direction: column;
      padding: 0 16px;
    }
  
    .paymentContainer {
      width: 100%;
      margin-left: 0;
      overflow-y: initial;
    }
  
    .row {
      flex-direction: column;
    }
  
  }
  