.table {
	width: 100%;
	border: none;
	margin-bottom: 20px;
}
.table thead th {
	font-weight: bold;
	text-align: left;
	border: none;
	padding: 10px 15px;
	background: #d8d8d8;
	font-size: 14px;
}
.table thead tr th:first-child {
	border-radius: 8px 0 0 8px;
}
.table thead tr th:last-child {
	border-radius: 0 8px 8px 0;
}
.table tbody td {
	text-align: right;
	border: none;
	padding: 10px 15px;
	font-size: 14px;
	vertical-align: top;
}
.table tbody tr td:first-child {
	border-radius: 8px 0 0 8px;
}
.table tbody tr td:last-child {
	border-radius: 0 8px 8px 0;
}


.total {
	text-align: right;
	font-size: 16px;
	padding-right: 16px;
}