.editButton {
    cursor: pointer;
    color: blue;
    font-weight: 600;
}
.editButtonWrapper {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
}
.actionButtons {
    display: flex;
    justify-content: flex-end;
}
.actionButtons > span:first-child {
    cursor: pointer;
    font-weight: 600;
    margin-right: 16px;
}
.actionButtons > span:last-child {
    cursor: pointer;
    color: blue;
    font-weight: 600;
}
.errorsMsgs > p {
    color:red;
    font-weight: 600;
    margin-top: 12px;
    font-size: 14px;
}
.errorsMsgs > p::before {
    content: '* ';
}
.guest {
    margin-top: 24px;
}
.roomRateDescription {
    font-weight: 600;
    font-size: 18px;
}