.Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 50;
    display: flex;
    cursor: default;
    align-items: center;
    justify-content: center;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
    min-width: 360px;
}
.Modal > div {
    display: flex;
    flex-direction: column;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.Modal__opened {
    animation-name: opening;
}
.Modal__closed {
    animation-name: closing;
}
.Modal__opening__default {
    animation-name: opening_default;
}
.Modal__closing__default {
    animation-name: closing_default;
}
.Modal__opening__slide {
    animation-name: opening_slide;
}
.Modal__closing__slide {
    animation-name: closing_slide;
}
@keyframes opening {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes closing {
    from { opacity: 1; }
    to { opacity: 0; }
}
@keyframes opening_default {
    from { transform: translateY(20px) scale(0.9); }
    to { transform: none; }
}
@keyframes closing_default {
    from { transform: none; }
    to { transform: translateY(-40px) scale(0.9); }
}
@keyframes opening_slide {
    from { transform: translateX(-100%); }
    to { transform: none; }
}
@keyframes closing_slide {
    from { transform: none; }
    to { transform: translateX(-100%); }
}
.CloseIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    align-self: flex-end;
    position: relative;
    top: -8px;
    right: -8px;
    margin-bottom: -24px;
    user-select: none;
}
.Title {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
}
@media screen and (max-width: 700px) {
    .Modal > div {
        max-width: calc(100% - 32px);
    }
}