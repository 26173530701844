.Dropdown {
    position: relative;
    max-width: 400px;
    min-width: 140px;
    width: 100%;
}
.Dropdown__readOnly > span::after {
    display: none;
}
.Dropdown > span {
    display: block;
    width: 100%;
    height: 58px;
    border-radius: 8px;
    outline: none;
    padding: 8px 16px 0 16px;
    font-size: 16px;
    border: 2px solid #808e9b;
    box-sizing: border-box;
    cursor: pointer;
    line-height: 48px;
    user-select: none;
    background-color: #ffffff;
}
.Dropdown > span::after {
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    background-image: url("../../icons/dropdown-chevron.svg");
    right: 16px;
    top: 12px;
    transition: 0.2s;
}
.Dropdown__opened > span::after {
    transform: rotate(180deg);
}
.Dropdown__label {
    position: absolute;
    left: 16px;
    top: 16px;
    font-size: 18px;
    color: #808e9b;
    transition: 0.15s;
    padding: 0 3px;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
}
.Dropdown__label__active {
    transform: translate(-3px, -15px);
    font-size: 14px;
    color: #15426d;
}
.Dropdown > p:hover + span, .Dropdown > span:hover, .Dropdown__opened > span {
    border-color: #15426d;
}
.Dropdown__wrapper {
    display: flex;
    position: absolute;
    top: 60px;
    z-index: 5;
    transform: translateY(-5px) scale(0.95);
    transition: 0.2s;
    opacity: 0;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    max-height: 200px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.Dropdown__wrapper__opened {
    transform: none;
    opacity: 1;
}
.Dropdown__wrapper__closed {
    transform: translateY(5px) scale(0.95);
    opacity: 0;
}
.Dropdown__m__wrapper__opened {
    opacity: 1;
}
.Dropdown__m__wrapper__closed {
    opacity: 0;
}
.Dropdown > div > span {
    padding: 12px 16px;
    font-size: 14px;
    color: #1e272e;
    user-select: none;
    background-color: #ffffff;
}
.Dropdown > div > span:hover {
    background-color: #f8f8f8;
    cursor: pointer;
}
.Dropdown > div > span:active {
    background-color: #f0f0f0;
}
.Dropdown > div::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
}
.Dropdown > div::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #dcdcdc;
}
.Dropdown > div:hover::-webkit-scrollbar-thumb {
    background-color: #c8c8c8;
}

@media screen and (max-width: 1200px) {
    .Dropdown > span {
        height: 48px;
        padding: 0 12px;
        font-size: 14px;
        line-height: 44px;
    }
    .Dropdown__label {
        left: 14px;
        top: 15px;
        font-size: 12px;
        font-weight: 700;
    }
    .Dropdown__label__active {
        font-size: 11px !important;
    }
}