.Button {
    margin-top: 16px;
    color: #15426d;
    border-radius: 8px;
    width: 100%;
    height: 46px;
    background-color: #ffffff;
    border: 2px solid #005DCB;
    outline: none;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
    transition: 0.2s;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Button__disabled {
    opacity: 0.6;
    cursor: default;
}
.Button__available:hover {
    cursor: pointer;
    transform: translateY(-4px);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px;
}
.Button__available:active {
    transform: translateY(-2px);
    box-shadow: none;
}

.BigButton {
    display: block;
    width: 300px;
    height: 80px;
    background-color: #15426d;
    line-height: 80px;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 14px;
    transition: 0.2s;
    border: 2px solid #15426d;
    border-radius: 40px;
    box-sizing: border-box;
    letter-spacing: 1px;
    outline: none;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.BigButton__disabled {
    opacity: 0.6;
    cursor: default;
}
.BigButton__available:hover {
    cursor: pointer;
    color: #000000;
    background-color: #ffffff !important;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
}
.BigButton__available:active {
    box-shadow: none;
    transform: scale(0.96);
}

.SmallButton {
    display: block;
    height: 36px;
    background-color: #15426d;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 12px;
    transition: 0.2s;
    border: none;
    border-radius: 6px;
    letter-spacing: 0.5px;
    box-sizing: border-box;
    outline: none;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.SmallButton__disabled {
    opacity: 0.6;
    cursor: default;
}
.SmallButton__available:hover {
    cursor: pointer;
    transform: translateY(-2px);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px;
}
.SmallButton__available:active {
    transform: translateY(-1px);
    box-shadow: none;
    background-color: #1e272e !important;
}