.Input {
    position: relative;
    min-width: 140px;
    width: 100%;
}
.Input > input {
    width: 100%;
    height: 58px;
    border-radius: 8px;
    outline: none;
    padding: 8px 16px 0 16px;
    font-size: 16px;
    border: 2px solid #808e9b;
    font-weight: 500;
    box-sizing: border-box;
}
.Input > input:hover, .Input > input:focus {
    border-color: #15426d;
}
.Input__readOnly > input:hover, .Input__readOnly > input:focus {
    border-color: #808e9b;
}

.InputIsRequired > span::before {
    content: "*";
    color: red;
    padding-right: 2px;
}
.Input > span {
    position: absolute;
    left: 16px;
    top: 18px;
    font-size: 18px;
    color: #808e9b;
    transition: 0.15s;
    padding: 0 2px 0 3px;
    font-weight: 700;
    cursor: text;
    user-select: none;
    pointer-events: none;
}
.InputValid > span, .Input > input:focus + span {
    transform: translate(-3px, -15px);
    font-size: 14px;
    color: #15426d;
}

@media screen and (max-width: 1200px) {
    .Input > input {
        height: 48px;
        border-radius: 8px;
        padding: 0 12px;
        font-size: 14px;
    }
    .Input > span {
        left: 14px;
        top: 15px;
        font-size: 12px;
        font-weight: 500;
    }
    .InputValid > span, .Input > input:focus + span {
        font-weight: 700;
        font-size: 11px;
    }
}
