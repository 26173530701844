:root {
    --DOUBLE_PI: 2 * 3.14159
}
.ActivityIndicator {
    animation: rotating 1.5s linear infinite;
}
.ActivityIndicator > circle {
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    animation: animating 0.75s linear infinite alternate;
}
@keyframes rotating {
    0% { transform: rotate(0); }
    50% { transform: rotate(180deg); }
    100% { transform: rotate(720deg); }
}
@keyframes animating {
    from { stroke-dasharray: calc(var(--DOUBLE_PI) * 50%); stroke-dashoffset: calc(var(--DOUBLE_PI) * 45%); }
    to { stroke-dasharray: calc(var(--DOUBLE_PI) * 50%); stroke-dashoffset: calc(var(--DOUBLE_PI) * 15%); }
}