.TextArea {
    position: relative;
    min-width: 140px;
    width: 100%;
}
.TextArea > textarea {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    outline: none;
    padding: 16px 16px 16px 16px;
    font-size: 16px;
    border: 2px solid #808e9b;
    font-weight: 500;
    box-sizing: border-box;
    resize: none;
    overflow-y: hidden;
    line-height: 24px;
}
.TextArea > textarea:hover, .TextArea > textarea:focus {
    border-color: #15426d;
}
.TextArea__readOnly > textarea:hover, .TextArea__readOnly > textarea:focus {
    border-color: #808e9b;
}

.TextAreaIsRequired > span::before {
    content: "*";
    color: red;
    padding-right: 2px;
}
.TextArea > span {
    position: absolute;
    left: 16px;
    top: 12px;
    font-size: 18px;
    color: #808e9b;
    transition: 0.15s;
    padding: 0 2px 0 3px;
    font-weight: 700;
    cursor: text;
    user-select: none;
    pointer-events: none;
}
.TextAreaValid > span, .TextArea > textarea:focus + span {
    transform: translate(-3px, -12px);
    font-size: 14px;
    color: #15426d;
}

@media screen and (max-width: 1200px) {
    .TextArea > textarea {
        height: 48px;
        border-radius: 8px;
        padding: 12px 12px;
        font-size: 14px;
    }
    .TextArea > span {
        left: 14px;
        top: 15px;
        font-size: 12px;
        font-weight: 500;
    }
    .TextAreaValid > span, .TextArea > textarea:focus + span {
        font-weight: 700;
        font-size: 11px;
    }
}
