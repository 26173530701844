.general > div {
    display: flex;
    align-items: center;
}

.general span {
    flex: 1;
    margin-right: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
}

.fileInput {
    display: none;
}

.general img {
    width: 22px;
    cursor: pointer;
}