.Switch {
    content: "";
    width: 32px;
    height: 32px;
    border: 2px solid black;
    cursor: pointer;
}
.Switch > img {
    width: 28px;
    height: 28px;
    transition: 0.2s;
    opacity: 0;
}
.Switch__active {
    width: 32px;
}
.Switch__active > img {
    opacity: 1;
}