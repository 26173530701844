.accept {
    display: flex;
    margin-top: 16px;
    
}

.accept > p {
    margin-left: 12px;
    margin-top: 4px;
}

.price {
    font-weight: 700;
    font-size: 28px;
}
.error {
    color:red;
    font-weight: 600;
    margin-top: 12px;
    font-size: 14px;
}
.impo {
    font-weight: 600;
    margin-top: 12px;
    font-size: 14px;
}
.confirmImportant {
    font-weight: 600;
    margin-top: 12px;
    font-size: 18px;
}
.icon {
    margin-left: 16px;
    width: 105px;
    height: 20px;
}